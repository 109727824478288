<template>
  
  <div class="car-view">

    <div class="back mx-auto max-2x">
      <div class="back-button -cardetail">
        <button @click="$router.go(-1)" >
          <img src="@/assets/img/back.svg">
          <span>Anterior</span>
        </button>
      </div><!-- .back-button -->
    </div>

    <app-cardetail></app-cardetail>
  </div>
</template>


<style type="text/css" lang="scss">
.car-view .back{
  max-width: 1400px;
}
</style>


<script>
// @ is an alias to /src
import CarDetail from '@/components/CarDetail'


export default {
  name: 'CarView',
  components: {
    "app-cardetail" : CarDetail
  },
  mounted ()   {
    document.body.classList.remove("grid-mode");
    window.scrollTo(0, 0);

    
  }
}
</script>
