<template>
  <footer class="footer">
        <div class="content-wrapper">
            <ul class="links">
                <li><a href="https://www.volvocars.com/pt/v/legal/cookies" target="_blank" >Cookies</a></li>
                <li><a href="https://www.volvocars.com/pt/legal/terms/terms-legal-notice " target="_blank" >Legal</a></li>
                <li><a href="https://www.volvocars.com/pt/legal/privacy/privacy-customer-privacy-policy" target="_blank" >Política de Privacidade</a></li>
                <li><a href="https://www.volvocars.com/pt/legal/privacy/privacy-terms-business-information" target="_blank" >Informações das empresas</a></li>
            </ul>
            <p class="legal mb-0">
              Todos os preços apresentados correspondem ao Preço Recomendado de Venda ao Público (PRVP) pelo importador (Volvo Car Portugal). Copyright © 2024 Volvo Car Corporation (ou as suas filiais ou licenciantes)<a href="https://www.volvocars.com/pt/v/legal/contacto-request" target="_blank">https://www.volvocars.com/pt/v/legal/contacto-request</a>
            </p>
        </div>
    </footer>
</template>

<script>

export default {
  name: 'MainFooter',
  props: {
  }
}
</script>

<style scoped lang="scss">

  @import "@/scss/_vars.scss";
  @import "@/scss/_responsive.scss";

    
  .footer {
    background: #fafafa; 
    padding: 30px 0;
    max-width: 1440px;
    margin: 0 auto;
    
    @include min-width("md") {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 35px 0 30px;
          font-size: 13px;
      }
      .content-wrapper {
          margin: 0 auto;
          width: 90%;
      }
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        color: black;
        margin: 0;
        justify-content: center;
        margin: 0 0 10px;
        @include min-width("md") {
              margin: 0 0 0 0;
          }
        a {
              color: black;
        }

        li {
            list-style-type: none;
            margin: 0 8px 5px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
        }
    }

    .legal {
          text-align: center;
          color: $primaryGray8C;
    }
  }

</style>
