export class util {
  static getModelKey(theCar) {
    let modelKey = "";
    let modelText = theCar.vehicle.model.description[0].text;
    let fuelType = theCar.vehicle.configuration.powerTrain.engine.fuelType;

    // console.log("FUEL TYPE OF THE VEHICLE:", fuelType);
    // console.log(theCar.vehicle.configuration.powerTrain.engine);

    switch (modelText) {
      case "EX30":
        modelKey = "ex30";
        break;

      case "XC90":
        modelKey = "xc90";
        if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "xc90-hybrid";
        }
        break;

      case "XC60":
        modelKey = "xc60";
        if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "xc60-hybrid";
        }
        break;

      case "XC40":
        modelKey = "xc40";
        if (fuelType == "PURE_ELECTRIC") {
          modelKey = "xc40-electric";
        } else if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "xc40-hybrid";
        }
        break;

      case "S90":
        modelKey = "s90";
        if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "s90-hybrid";
        }
        break;

      case "S60":
        modelKey = "s60";
        if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "s60-hybrid";
        }
        break;

      case "V90 Cross Country":
        modelKey = "v90-cross-country";
        break;

      case "V60 Cross Country":
        modelKey = "v60-cross-country";
        break;

      case "V90":
        modelKey = "v90-hybrid"; // Maybe all the V90's are hybrid
        if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "v90-hybrid";
        }
        break;

      case "V60":
        modelKey = "v60";
        if (fuelType == "PETROL_ELECTRIC") {
          modelKey = "v60-hybrid";
        }
        break;

      case "C40":
        modelKey = "c40-electric";
        break;
    }

    return modelKey;
  }

  static readModelKey(theModelkey) {
    let cfg = null;

    // console.log("readModelKey");
    // console.log(theModelkey);

    switch (theModelkey) {
      case "xc90":
        cfg = {
          model: ["XC90"],
          textDisplay: ["XC90"],
          //combustible: []
          combustible: ["petrol", "diesel"],
        };
        break;

      case "xc90-hybrid":
        cfg = {
          model: ["XC90"],
          textDisplay: ["XC90"],
          combustible: ["electric-petrol"],
        };
        break;

      case "xc60":
        cfg = {
          model: ["XC60"],
          textDisplay: ["XC60"],
          //combustible: []
          combustible: ["petrol", "diesel"],
        };
        break;

      case "xc60-hybrid":
        cfg = {
          model: ["XC60"],
          textDisplay: ["XC60 Recharge"],
          combustible: ["electric-petrol"],
        };
        break;

      case "xc40":
        cfg = {
          model: ["xc40"],
          textDisplay: ["XC40"],
          //combustible: []
          combustible: ["petrol", "diesel"],
        };
        break;

      case "xc40-electric":
        cfg = {
          model: ["xc40"],
          textDisplay: ["XC40 Eléctrico"],
          combustible: ["electric"],
        };
        break;

      case "xc40-hybrid":
        cfg = {
          model: ["xc40"],
          textDisplay: ["XC40"],
          combustible: ["electric-petrol"],
        };
        break;

      case "s90":
        cfg = {
          model: ["S90"],
          textDisplay: ["S90"],
          combustible: ["petrol", "diesel"],
        };
        break;

      case "s90-hybrid":
        cfg = {
          model: ["S90"],
          textDisplay: ["S90 Recharge"],
          combustible: ["electric-petrol"],
        };
        break;

      case "s60":
        cfg = {
          model: ["S60"],
          textDisplay: ["S60"],
          combustible: ["petrol", "diesel"],
        };
        break;

      case "s60-hybrid":
        cfg = {
          model: ["S60"],
          textDisplay: ["S60 Recharge"],
          combustible: ["electric-petrol"],
        };
        break;

      case "v90-cross-country":
        cfg = {
          model: ["V90 Cross Country"],
          textDisplay: ["V90 Cross Country"],
          combustible: ["diesel"],
        };
        break;

      case "v60-cross-country":
        cfg = {
          model: ["V60 Cross Country"],
          textDisplay: ["V60 Cross Country"],
          combustible: ["petrol", "diesel"],
        };
        break;

      case "v90":
        cfg = {
          model: ["V90"],
          textDisplay: ["V90"],
          //combustible: []
          combustible: ["petrol", "diesel"],
        };
        break;

      case "v90-hybrid":
        cfg = {
          model: ["V90"],
          textDisplay: ["V90 Recharge"],
          combustible: ["electric-petrol"],
        };
        break;

      case "v60":
        cfg = {
          model: ["V60"],
          textDisplay: ["V60"],
          //combustible: []
          combustible: ["petrol", "diesel"],
        };
        break;

      case "v60-hybrid":
        cfg = {
          model: ["V60"],
          textDisplay: ["V60 Recharge"],
          combustible: ["electric-petrol"],
        };
        break;

      case "c40-electric":
        cfg = {
          model: ["C40"],
          textDisplay: ["C40"],
          combustible: ["electric"],
        };
        break;

      case "ex90-electric":
        cfg = {
          model: ["EX90"],
          textDisplay: ["EX90"],
          combustible: ["electric"],
        };
        break;

      case "ex30-electric":
        cfg = {
          model: ["EX30"],
          textDisplay: ["EX30"],
          combustible: ["electric"],
        };
        break;

      default:
        cfg = {
          model: [],
          textDisplay: [],
          combustible: [],
        };
    }

    return cfg;
  }

  static getFamiliesRelations() {
    //Recomienda coches de las mismas familias: SUV, familiar....
    return [
      ["XC90", "XC40", "XC60", "EX90", "EX30", "C40"],
      ["S90", "S60"],
      ["V90", "V90 Cross Country", "V60", "V60 Cross Country"],
    ];
  }

  //also with the commonsalestype
  static filterByDealerId() {
    return {
      dealerId: {
        not: true,
        value: [
          {
            value: "6PT4201",
          },
          {
            value: "6PT4203",
          },
          {
            value: "6PT4000",
          },
          {
            value: "6PT8996",
          },
          {
            value: "6PT8997",
          },
          {
            value: "6PT8998",
          },
          {
            value: "6PT8999",
          },
          {
            value: "6PT9999",
          },
          {
            value: "6PT7999",
          },
          {
            value: "6PT6999",
          },
          {
            value: "6PT8995",
          },
          {
            value: "6PT5999",
          },
          {
            value: "6PT4999",
          },
          {
            value: "6PT7898",
          },
          {
            value: "6PT7997",
          },
          {
            value: "6PT7998",
          },
        ],
      },
      commonSalesType: {
        value: [
          {
            value: "30A",
          },
        ],
      },
    };
  }
}
