"use strict";

let _singleton = null;

class Tracking {
  constructor(data) {
    if (!_singleton) {
      this.data = data;
      _singleton = this;
      this.init(data);
    } else {
      return _singleton;
      // console.log("Singleton class created")
    }

    this.ready = false;
  }

  init() {}

  load() {
    // const head = document.getElementsByTagName("head")[0];

    // var myScript = document.createElement('script');

    // myScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-YWHWQZNW82');

    // myScript.onload = () => {
    //     window.dataLayer = window.dataLayer || [];

    //     function gtag() { dataLayer.push(arguments); }
    //     gtag('js', new Date());

    //     console.log('window.dataLayer Executed', window.dataLayer)

    //     gtag('config', 'G-YWHWQZNW82');

    //     this.ready = true;
    // }

    // head.insertBefore(myScript, head.children[1]);

    //this.script_TagManagerHead = "<script async src='https://www.googletagmanager.com/gtag/js?id=G-8Q0KS2W96E'></script>";

    // this.script_TagManagerScript = "<script async src='https://www.googletagmanager.com/gtag/js?id=G-8Q0KS2W96E'></script>";

    var scriptToLoad = document.createElement("script");
    scriptToLoad.setAttribute("async", true);
    scriptToLoad.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=G-WCM04VZX0B"
    ); //StockVolvoCars Portugal
    document.head.appendChild(scriptToLoad);

    //this.script_TagManagerHead = "<script async src='https://www.googletagmanager.com/gtag/js?id=G-8Q0KS2W96E'></script>";
    // this.script_TagManagerHead += "<script>";

    // this.script_TagManagerHead = "<script>";
    // this.script_TagManagerHead += "window.dataLayer = window.dataLayer || [];";
    // this.script_TagManagerHead += "function gtag(){dataLayer.push(arguments);}";
    // this.script_TagManagerHead += "gtag('js', new Date());";
    // this.script_TagManagerHead += "gtag('config', 'G-8Q0KS2W96E');";
    // this.script_TagManagerHead += "</script>";

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", "G-WCM04VZX0B");

    // $("body").append(this.script_TagManagerHead);
    // document.body.appendChild(this.script_TagManagerHead);
    // document.body.appendChild(this.script_TagManagerHead);
    // document.body.insertAdjacentHTML("beforeend", this.script_TagManagerHead);

    // this.loadFB();

    this.ready = true;
  }

  loadFB() {
    // this.script_FbPixel = "<script>";
    // this.script_FbPixel += "!function(f,b,e,v,n,t,s)";
    // this.script_FbPixel += "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?";
    // this.script_FbPixel += "n.callMethod.apply(n,arguments):n.queue.push(arguments)};";
    // this.script_FbPixel += "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';";
    // this.script_FbPixel += "n.queue=[];t=b.createElement(e);t.async=!0;";
    // this.script_FbPixel += "t.src=v;s=b.getElementsByTagName(e)[0];";
    // this.script_FbPixel += "s.parentNode.insertBefore(t,s)}(window, document,'script',";
    // this.script_FbPixel += "'https://connect.facebook.net/en_US/fbevents.js');";
    // this.script_FbPixel += "fbq('init', '118629082143399');";
    // this.script_FbPixel += "fbq('track', 'PageView');";
    // this.script_FbPixel += "</script>";
    // this.script_FbPixel += "<noscript><img height='1' width='1' style='display:none'";
    // this.script_FbPixel += "src='https://www.facebook.com/tr?id=118629082143399&ev=PageView&noscript=1'";
    // this.script_FbPixel += "/></noscript>";
    // $("body").append(this.script_FbPixel);
  }

  tag(theTag) {
    if (this.ready) {
      console.log("tag event " + theTag);
      window.gtag("event", theTag);
    }
  }

  // trackSuccess (theTag) {

  //     if(this.ready) {

  //         console.log("trackSuccess " + theTag)
  //         fbq ('track','lead');

  //         // window.dataLayer ({
  //         //     'event' : theTag
  //         // });

  //         dataLayer.push({'event': theTag});

  //         fbq('trackCustom', theTag);
  //     }

  // }
}

export default Tracking;
